import moment from 'moment-timezone'

import keys from '../config/keys'
import store from '../redux/store'
import { NoDataUI } from './noData'

export const DEFAULT_TIME_FORMAT = `HH:mm`
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD' // It's the correct format for native date input value
export const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const getAppInputDateFormat = () => {
	const { user: userProfile } = store.getState().auth?.user || {}
	const { input_date_format } = userProfile || {}
	const date_format = input_date_format || DEFAULT_DATE_FORMAT
	return { date_format, datetime_format: `${date_format} ${DEFAULT_TIME_FORMAT}` }
}

export const getAppTimezone = ({ display } = {}) => {
	const isEvent = window.location.href.includes(`/${keys.MODULES.events}/`)
	const info = store.getState().currentData?.viewAndEdit || {}

	const key = `microsite_timezone` + (display ? '_display' : '')

	const timezone =
		(isEvent ? info?.[key] ?? undefined : info?.[`event_${key}`] ?? undefined) ||
		(display ? undefined : window[keys.DEFAULT_TIMEZONE])

	return timezone
}

export const validateAppTime = (inputDate, { timezone, attach = false } = {}) => {
	const app_timezone = timezone || getAppTimezone()

	/* don't want to convert the time into the target timezone but just want to "attach" the timezone offset (like +01:00) */
	if (attach) return moment(inputDate).tz(app_timezone, true)

	// When viewing an Event, the date/times must be in the event timezone.
	return moment(inputDate).tz(app_timezone)
}

// To feed the form input field
export const prepareTime = (input, { type = 'datetime' } = {}) => {
	if (type === 'date') {
		// For native date input
		// return validateAppTime(input).format(DEFAULT_DATE_FORMAT) // For 'date' no timezone related logic needed
		return moment(input).format(DEFAULT_DATE_FORMAT)
	}

	// For AntD datetime input
	return validateAppTime(input)
}

// To pass along with POST/PATCH AJAX call
export const pullTime = (input, { type = 'datetime' } = {}) => {
	if (type === 'date') return moment(input).format(DEFAULT_DATE_FORMAT) // For 'date' no timezone related logic needed
	return validateAppTime(input, { attach: true }).format(ISO_8601_FORMAT)
}

const formatDateIntl = (date, options) => {
	const { user: userProfile } = store.getState().auth?.user || {}
	const { locale_date_format: locale } = userProfile || {}
	// Enables language-sensitive date and time formatting
	let str = new Intl.DateTimeFormat(locale || 'en-GB', options).format(date)
	str = str.replace(/\//g, '-').replace(/,/g, '')
	return str
}

// To view only
export const previewTime = (input, { type = 'datetime', showEmpty = false } = {}) => {
	if (!input) {
		if (showEmpty) return ''
		return NoDataUI
	}

	const momentDate = type === 'date' ? moment(input) : validateAppTime(input)
	const nativeDate = momentDate.toDate() // Convert moment object to native Date object

	const common = {
		timeZone: type === 'date' ? undefined : getAppTimezone(),
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}

	if (type === 'date') {
		// Output: 'DD-MM-YYYY' (As moment format)
		return formatDateIntl(nativeDate, { ...common })
	}

	// Output: 'DD-MM-YYYY HH:mm' (As moment format)
	return formatDateIntl(nativeDate, {
		...common,
		hour12: false, // 24 hour format
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	})
}
